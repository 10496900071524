import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import Wrapper from '../Wrapper';
import RouterLink from '../RouterLink';
import Button from '../Button';
import Logo from '../Logo';

import Item from './Item';
import links from './links';

import { apiUrl } from '../../environment';

function Menu() {
  return (
    <Box>
      <Wrapper px={4}>
        <Flex
          display={['flex', 'flex', 'flex', 'none']}
          justifyContent="space-around"
          alignItems="center"
          height={90}
          color="white"
        >
          <Box flex={1} />
          <Box>
            <Logo />
          </Box>
          <Flex flex={1} justifyContent="flex-end">
            <Item href="mailto:hello@hifiv.es" isExternal>
              Support
            </Item>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-around"
          display={['none', 'none', 'none', 'flex']}
          alignItems="center"
          height={90}
        >
          <Flex color="white" justifyContent="center" alignItems="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
            <Flex ml="8" flex={1}>
              {links.map((item) => (
                <Item key={item.href} href={item.href} isExternal={item.isExternal}>
                  {item.title}
                </Item>
              ))}
            </Flex>
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <a href={`${apiUrl}signup/slack`}>
              <Button small variantColor="yellow">
                Connect to Slack
              </Button>
            </a>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default Menu;
