import React from 'react';
import { Box } from '@chakra-ui/core';

const Card = ({ children, minHeight, width }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    bg="white"
    width={width}
    minHeight={minHeight}
  >
    {children}
  </Box>
);

export default Card;
