import React from 'react';
import {
  Box, Grid, Text, Image,
} from '@chakra-ui/core';

import Wrapper from '../../../components/Wrapper';

import leaderboard from './images/leaderboard.jpg';
import leaderboard2x from './images/leaderboard@2x.jpg';

import hifive from './images/hifive.jpg';
import hifive2x from './images/hifive@2x.jpg';

function Features() {
  const padding = ['12', '12', '12', '16'];
  return (
    <Box position="relative" bg="white">
      <Wrapper px={['4', '4', '8', '8']} py={['4', '4', '16', '32']} position="relative">
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={['4', '4', '10', '20']}
        >
          <Box bg="gray.50" pt={padding} overflow="hidden">
            <Text fontSize="3xl" fontFamily="heading" pl={padding}>
              High five someone
            </Text>
            <Text px={padding} maxWidth={543}>
              When someone helped you with something, posted something you like or is just being fun
              give them a highfive by using /highfive or
              {' '}
              <span role="img" aria-label="hand">
                ✋
              </span>
              {' '}
              emoji.
            </Text>

            <Box pl={padding} pr={['12', '12', 0]}>
              <Image
                borderTopLeftRadius={10}
                borderBottomLeftRadius={[10, 10, 0]}
                borderTopRightRadius={[10, 10, 0]}
                borderBottomRightRadius={[10, 10, 0]}
                mb={['12', '12', 0]}
                maxWidth={['100%', '100%', '100%']}
                mt="10"
                src={hifive}
                srcSet={`${hifive} 1x,${hifive2x} 2x`}
                alt="Slack High Five"
              />
            </Box>
          </Box>
          <Box bg="pink.100" pt={padding}>
            <Text fontSize="3xl" fontFamily="heading" pl={padding}>
              Stats
            </Text>
            <Text px={padding} maxWidth={543}>
              At any time you can track who has given the most high fives and who received the most
              in that month or all time.
            </Text>
            <Box pl={padding} pr={['12', '12', 0]}>
              <Image
                borderTopLeftRadius={10}
                borderBottomLeftRadius={[10, 10, 0]}
                borderTopRightRadius={[10, 10, 0]}
                borderBottomRightRadius={[10, 10, 0]}
                mb={['12', '12', 0]}
                maxWidth={['100%', '100%', '100%']}
                mt="10"
                src={leaderboard}
                srcSet={`${leaderboard} 1x,${leaderboard2x} 2x`}
                alt="Slack High Five Leaderboard"
              />
            </Box>
          </Box>
        </Grid>
      </Wrapper>
    </Box>
  );
}

export default Features;
