import React from 'react';

import { motion } from 'framer-motion';

export default function Floating({
  children, distance = 10, delay = 0, duration = 4,
}) {
  return (
    <motion.div
      animate={{
        y: [distance, 0, distance],
      }}
      transition={{
        ease: 'easeInOut',
        loop: Infinity,
        duration,
        delay,
      }}
    >
      {children}
    </motion.div>
  );
}
