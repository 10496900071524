import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import { AuthContext } from './context/auth'

import Home from './pages/Home'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import SignUpSuccess from './pages/SignUpSuccess'
import UpgradeSuccess from './pages/UpgradeSuccess'
import BackToSlack from './pages/BackToSlack'

import { usePersistedState } from './utils/usePersistedState'

function App() {
    const tokenKey = 'token'
    const [authToken, setAuthToken] = usePersistedState(null, tokenKey)

    const setToken = (token) => {
        setAuthToken(token)
    }

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/signup-success" component={SignUpSuccess} />
                <Route
                    exact
                    path="/upgrade-success"
                    component={UpgradeSuccess}
                />
                <Route exact path="/back-to-slack" component={BackToSlack} />
            </Switch>
        </AuthContext.Provider>
    )
}

export default withRouter(App)
