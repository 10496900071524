import React from 'react'
import { Box, Text } from '@chakra-ui/core'

import Menu from '../../components/Menu'
import Page from '../../components/Page'
import Wrapper from '../../components/Wrapper'
import Footer from '../../components/Footer'
import Button from '../../components/Button'

import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { slackAppId } from '../../environment'

function SignUpSuccess() {
    const { search } = useLocation()
    const values = queryString.parse(search)

    return (
        <Page
            metaData={{
                title: 'Sign up success',
            }}
        >
            <Menu />
            <Wrapper
                px={4}
                pb="32"
                pt="32"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <Box maxWidth={500} width="100%" p={4} textAlign="center">
                    <Text
                        fontSize={[20, 20, 30, 40]}
                        fontFamily="heading"
                        letterSpacing="tight"
                        fontWeight="bold"
                        marginLeft="auto"
                        marginRight="auto"
                        as="h1"
                        color="brand.beige"
                        lineHeight={1.1}
                    >
                        Sign up successful!{' '}
                        <span role="img" aria-label="party popper">
                            🎉
                        </span>
                    </Text>
                    <Text
                        fontSize={[13, 13, 13, 16]}
                        marginLeft="auto"
                        marginRight="auto"
                        color="brand.beige"
                        mt={5}
                        mb={5}
                    >
                        Head over to Slack as we've sent you a message with
                        instructions to start high fiving.
                    </Text>

                    <a
                        href={`https://slack.com/app_redirect?app=${slackAppId}&team=${values.team}`}
                    >
                        <Button variantColor="yellow" size="lg" mt="6" small>
                            View instructions
                        </Button>
                    </a>
                </Box>
            </Wrapper>
            <Footer />
        </Page>
    )
}

export default SignUpSuccess
