import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@chakra-ui/core';

function Page({ metaData, children, bg = 'gray.800' }) {
  const defaultTitle = 'High Five - Say thanks with a high five on Slack';
  return (
    <Box bg={bg} minHeight="100vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData && metaData.title ? `${metaData.title} - High Five` : defaultTitle}</title>
      </Helmet>
      {children}
    </Box>
  );
}

export default Page;
