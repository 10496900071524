import React from 'react';
import {
  Box, Text, Flex, Image,
} from '@chakra-ui/core';

import Wrapper from '../../../components/Wrapper';
import PlansCard from '../../../components/PlansCard';

import man from './images/man.jpg';
import man2x from './images/man@2x.jpg';
import office from './images/office.jpg';
import office2x from './images/office@2x.jpg';

function Pricing() {
  return (
    <Box id="pricing" position="relative" overflowX="hidden">
      <Wrapper px={4} pt={['16', '16', '16', '32']} pb={['4', '4', '4', '32']}>
        <Text
          fontSize={['4xl', '4xl', '5xl']}
          fontFamily="heading"
          fontWeight="bold"
          textAlign={['center', 'center', 'center', 'left']}
          as="h1"
          lineHeight="shorter"
          color="brand.beige"
        >
          Start a month free,
          <br />
          no credit card required.
        </Text>
        <Flex justifyContent="center" alignItems="stretch">
          <Box pt="12" width={['100%', 381, 381]}>
            <PlansCard />
          </Box>
          <Box position="relative" flex={1} display={['none', 'none', 'none', 'block']}>
            <Box position="absolute" right={-124} top={0}>
              <Image
                src={office}
                srcSet={`${office} 1x,${office2x} 2x`}
                width={[299, 299, 299, 598]}
                height="auto"
              />
            </Box>
            <Box position="absolute" left={82} bottom={0}>
              <Image
                src={man}
                srcSet={`${man} 1x,${man2x} 2x`}
                width={[256, 256, 256, 513]}
                height="auto"
              />
            </Box>
          </Box>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default Pricing;
