import React from 'react';
import {
  Box, Text, Flex, Image,
} from '@chakra-ui/core';

import Wrapper from '../../../components/Wrapper';
import Button from '../../../components/Button';
import FloatingHighFive from './FloatingHighFive';
import Floating from './Floating';

import { apiUrl } from '../../../environment';

import hifive from './images/hifive.jpg';
import hifive2x from './images/hifive@2x.jpg';

import man from './images/man.jpg';
import man2x from './images/man@2x.jpg';

import woman from './images/woman.jpg';
import woman2x from './images/woman@2x.jpg';

function Home() {
  return (
    <Box overflowX="hidden">
      <Wrapper px={4} pb={['24', '24', '24', '48']} pt={['8', '8', '8', 140]}>
        <Flex flexDirection={['column', 'column', 'column', 'row']}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems={['center', 'center', 'center', 'flex-start']}
          >
            <Text
              fontSize={[50, 50, 60, 90]}
              fontFamily="heading"
              letterSpacing="tight"
              textAlign={['center', 'center', 'center', 'left']}
              maxWidth={577}
              fontWeight="bold"
              marginLeft="auto"
              marginRight="auto"
              as="h1"
              color="brand.beige"
              lineHeight={1.1}
            >
              Say thanks on Slack with a high five
            </Text>
            <Text
              color="brand.beige"
              maxWidth={450}
              fontSize={['l', 'l', 'l', 'xl']}
              mt="4"
              mb="6"
              textAlign={['center', 'center', 'center', 'left']}
            >
              Boost your team morale, recognise awesomeness and simply make Slack more fun by using
              High Five! It&apos;s a great way to display and track recognition to bring people
              together, now that we are further apart.
            </Text>
            <a href={`${apiUrl}signup/slack`}>
              <Button variantColor="yellow" size="lg" mt="6">
                Connect to Slack
              </Button>
            </a>
          </Flex>
          <Flex
            flex={1}
            justifyContent={['center', 'center', 'center', 'flex-end']}
            alignItems="center"
            pt={['20', '20', '20', 0]}
          >
            <Box position="relative" width={[269, 349, 389, 453]} height={[203, 263, 293, 341]}>
              <Box position="absolute" left="5%" top="-22%">
                <Floating distance={10} duration={3}>
                  <Box
                    width={[60, 60, 60, 76]}
                    height={[60, 60, 60, 76]}
                    transform="rotate(-10deg)"
                  >
                    <Image
                      borderRadius={38}
                      src={man}
                      srcSet={`${man} 1x,${man2x} 2x`}
                      alt="Man with a hat on"
                      position="absolute"
                    />
                  </Box>
                </Floating>
              </Box>
              <Box position="absolute" right="-8%" top="-17%">
                <FloatingHighFive size={[60, 60, 60, 80]} rotation={-20} />
              </Box>
              <Image
                borderRadius={10}
                src={hifive}
                srcSet={`${hifive} 1x,${hifive2x} 2x`}
                alt="Slack High Five"
                position="absolute"
              />
              <Box position="absolute" right="10%" bottom="-10%">
                <FloatingHighFive
                  size={[60, 70, 80, 110]}
                  color={0}
                  bg="gray.50"
                  rotation={-20}
                  distance={12}
                  duration={5}
                />
              </Box>
              <Box position="absolute" left="-10%" bottom="45%">
                <FloatingHighFive
                  size={[50, 50, 60, 80]}
                  color={1}
                  bg="pink.100"
                  rotation={20}
                  distance={9}
                  duration={4}
                />
              </Box>
              <Box position="absolute" right="-8%" top="50%">
                <FloatingHighFive
                  size={[35, 35, 35, 50]}
                  color={2}
                  bg="pink.100"
                  rotation={-20}
                  distance={9}
                  duration={3}
                />
              </Box>
              <Box position="absolute" left="-7%" bottom="-5%">
                <Floating distance={10} duration={6}>
                  <Box width={76} height={76} transform="rotate(-10deg)">
                    <Image
                      borderRadius={38}
                      src={woman}
                      srcSet={`${woman} 1x,${woman2x} 2x`}
                      alt="Woman"
                      position="absolute"
                    />
                  </Box>
                </Floating>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default Home;
