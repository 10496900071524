import React from 'react';
import { Box } from '@chakra-ui/core';

import Header from './Header';
import Features from './Features';
import Pricing from './Pricing';
import BigText from './BigText';
import Page from '../../components/Page';
import Menu from '../../components/Menu';
import ScrollToTop from '../../components/ScrollToTop';
import Footer from '../../components/Footer';

function Home() {
  return (
    <Page>
      <ScrollToTop />
      <Box bg="green">
        <Menu />
        <Header />
        <Features />
        <Pricing />
        <BigText />
        <Footer />
      </Box>
    </Page>
  );
}

export default Home;
