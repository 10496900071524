import React, { useState } from 'react'
import { Box, Text, Flex, Select } from '@chakra-ui/core'

import Card from '../Card'
import CheckIcon from './CheckIcon'

export const heightCard = [516, 516, 516, 516]

function PlansCard({ children, onChange }) {
    const [totalUser, setTotalUsers] = useState(7)

    const maxUsersCopy = `Up to ${totalUser} people`

    return (
        <Card minHeight={heightCard}>
            <Box flex={1} px="12" pt="12" pb="12">
                <Text fontFamily="heading" fontSize="2xl">
                    Number of employees
                </Text>
                <Select
                    size="md"
                    my="4"
                    value={totalUser}
                    onChange={(e) => setTotalUsers(e.target.value)}
                >
                    {Array.from({ length: 2000 }, (_, i) => i + 1).map(
                        (number, index) => (
                            <option key={index} value={index}>
                                Up to {number}
                            </option>
                        )
                    )}
                </Select>
                <Box mt="5">
                    <ListItem>Unlimited High Fives</ListItem>
                    <ListItem>{maxUsersCopy}</ListItem>
                    <ListItem>Monthly ranking board</ListItem>
                    <ListItem>All time ranking board</ListItem>
                </Box>
                <Flex
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    flexDirection="row"
                >
                    <Text
                        fontFamily="script"
                        fontSize={100}
                        lineHeight="none"
                        mt="2"
                    >
                        ${totalUser * 1.5}
                    </Text>
                    <Text fontSize="lg" fontFamily="heading">
                        /month
                    </Text>
                </Flex>
                {children}
            </Box>
            <Box bg="brand.yellow" width="100%">
                <Text
                    fontSize="sm"
                    textAlign="center"
                    fontWeight="medium"
                    py={3}
                >
                    Subscriptions can be canceled at any time
                </Text>
            </Box>
        </Card>
    )
}

function ListItem({ children }) {
    return (
        <Flex alignItems="center" py={2}>
            <CheckIcon />
            <Text ml="3" fontSize="lg">
                {children}
            </Text>
        </Flex>
    )
}

export default PlansCard
