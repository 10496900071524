import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';

import Wrapper from '../Wrapper';
import Logo from '../Logo';
import RouterLink from '../RouterLink';

function BigText() {
  return (
    <Box borderTopStyle="solid" borderTopColor="gray.700" borderTopWidth={1}>
      <Wrapper px={4}>
        <Flex justifyContent="space-between" height="100%" alignItems="center" minHeight={163}>
          <Box flex={1}>
            <Text fontSize="sm" fontFamily="heading" color="brand.beige">
              Made in London
            </Text>
          </Box>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
          >
            <Text fontSize="sm" fontFamily="heading" textAlign="center" color="brand.beige">
              Or read our boring legals
            </Text>
            <Text fontSize="sm" fontFamily="heading" textAlign="center" color="brand.beige">
              <RouterLink to="/privacy">Privacy</RouterLink>
              &nbsp;&&nbsp;
              <RouterLink to="/terms">Terms</RouterLink>
            </Text>
          </Flex>
          <Flex color="brand.beige" flex={1} justifyContent="flex-end">
            <Logo width={50} height="auto" />
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default BigText;
