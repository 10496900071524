import React from 'react';
import { Flex, Image } from '@chakra-ui/core';

import Floating from './Floating';

import color1 from './images/color1.png';
import color12x from './images/color1@2x.png';

import color2 from './images/color2.png';
import color22x from './images/color2@2x.png';

import color3 from './images/color3.png';
import color32x from './images/color3@2x.png';

import color4 from './images/color4.png';
import color42x from './images/color4@2x.png';

function FloatingHighFive({
  color = 3,
  bg = 'brand.yellow',
  size = 50,
  rotation = -20,
  distance,
  delay,
  duration,
}) {
  const styles = [
    {
      image: color1,
      image2x: color12x,
    },
    {
      image: color2,
      image2x: color22x,
    },
    {
      image: color3,
      image2x: color32x,
    },
    {
      image: color4,
      image2x: color42x,
    },
  ];

  const images = styles[color];

  return (
    <Floating distance={distance} delay={delay} duration={duration}>
      <Flex
        bg={bg}
        width={size}
        height={size}
        borderRadius="100%"
        transform={`rotate(${rotation}deg)`}
      >
        <Image
          width="100%"
          src={images.image}
          srcSet={`${images.image} 1x,${images.image2x} 2x`}
          alt="Emoji Hand"
        />
      </Flex>
    </Floating>
  );
}

export default FloatingHighFive;
