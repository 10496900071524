import React from 'react'
import { Box, Text } from '@chakra-ui/core'

import Menu from '../../components/Menu'
import Page from '../../components/Page'
import Wrapper from '../../components/Wrapper'
import Footer from '../../components/Footer'

function BackToSlack() {
    return (
        <Page
            metaData={{
                title: 'Back to Slack',
            }}
        >
            <Menu />
            <Wrapper
                px={4}
                pb="32"
                pt="32"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
            >
                <Box maxWidth={500} width="100%" p={4} textAlign="center">
                    <Text
                        fontSize={[20, 20, 30, 40]}
                        fontFamily="heading"
                        letterSpacing="tight"
                        fontWeight="bold"
                        marginLeft="auto"
                        marginRight="auto"
                        as="h1"
                        color="brand.beige"
                        lineHeight={1.1}
                    >
                        Return to Slack
                    </Text>
                    <Text
                        fontSize={[13, 13, 13, 16]}
                        marginLeft="auto"
                        marginRight="auto"
                        color="brand.beige"
                        mt={5}
                    >
                        You can now return to Slack to get back to high fiving.
                    </Text>
                </Box>
            </Wrapper>
            <Footer />
        </Page>
    )
}

export default BackToSlack
