import React from 'react'
import { Text, Link } from '@chakra-ui/core'

import Menu from '../../components/Menu'
import Page from '../../components/Page'
import Wrapper from '../../components/Wrapper'
import Footer from '../../components/Footer'

function Privacy() {
    const Paragraph = ({ children }) => (
        <Text
            pb={4}
            fontSize="lg"
            color="white"
            marginLeft="auto"
            marginRight="auto"
            as="p"
        >
            {children}
        </Text>
    )

    const ListItem = ({ children }) => (
        <Text
            pb={4}
            fontSize="lg"
            color="white"
            marginLeft="auto"
            marginRight="auto"
            as="li"
        >
            {children}
        </Text>
    )

    return (
        <Page
            metaData={{
                title: 'Privacy',
            }}
        >
            <Menu />
            <Wrapper px={4} pb="32" pt="32">
                <Text
                    pb="16"
                    fontSize="4xl"
                    fontFamily="heading"
                    marginLeft="auto"
                    color="white"
                    marginRight="auto"
                    as="h1"
                >
                    Privacy
                </Text>
                <Paragraph>
                    Your privacy is important to us. It is High Five's policy to
                    respect your privacy regarding any information we may
                    collect from you across our website,{' '}
                    <Link href="https://hifiv.es">hifiv.es</Link>, our
                    app for Slack, and other sites we own and operate, including any
                    interactions within the app for Slack.
                </Paragraph>

                <Paragraph>
                    We only ask for personal information when we truly need it
                    to provide a service to you. We collect it by fair and
                    lawful means, with your knowledge and consent. We also let
                    you know why we’re collecting it and how it will be used.
                </Paragraph>
                <Paragraph>
                    We only retain collected information for as long as
                    necessary to provide you with your requested service. What
                    data we store, we’ll protect within commercially acceptable
                    means to prevent loss and theft, as well as unauthorized
                    access, disclosure, copying, use, or modification.
                </Paragraph>

                <Paragraph>
                    For users of our app for Slack, please note that we may collect
                    certain information such as user IDs, conversation data, and
                    other relevant metadata to ensure the functionality and
                    security of the app. This data will be treated with the same
                    level of care and security as outlined in this privacy
                    policy.
                </Paragraph>

                <Text
                    pb={5}
                    pt={5}
                    fontSize="2xl"
                    fontFamily="heading"
                    marginLeft="auto"
                    color="white"
                    marginRight="auto"
                    as="h2"
                >
                    What information we collect
                </Text>
                <Paragraph>
                    Upon integrating High Five with Slack, we gather the
                    requisite data essential for the optimal functioning of our
                    services. Outlined below is a breakdown of the information
                    we collect, along with the rationale behind it:
                </Paragraph>

                <Text as="ul" marginBottom={8}>
                    <ListItem>
                        <b>Workspace Name and Identifier:</b> This encompasses
                        the name and unique identifier of your Slack workspace,
                        enabling seamless association with our services. High
                        Five
                    </ListItem>
                    <ListItem>
                        <b>Emoji-embedded Messages:</b> We capture messages
                        containing a high five emoji within their content. This
                        allows us to recognize instances of positive
                        recognition.
                    </ListItem>
                    <ListItem>
                        <b>
                            Commands starting with /highfive, /glossary, or
                            /personality:
                        </b>{' '}
                        This results in the collection of data related to those
                        commands. This data collection ensures the accurate
                        execution of the corresponding actions.
                    </ListItem>
                </Text>
                <Paragraph>
                    We don’t share any personally identifying information
                    publicly or with third parties, except when required to by
                    law or necessary to provide the services within our app for
                    Slack.
                </Paragraph>
                <Paragraph>
                    Our website and app for Slack may link to external sites that
                    are not operated by us. Please be aware that we have no
                    control over the content and practices of these sites, and
                    cannot accept responsibility or liability for their
                    respective privacy policies.
                </Paragraph>
                <Paragraph>
                    You are free to refuse any request we may make for your
                    personal information, with the understanding that we may be
                    unable to provide you with some of your desired services or
                    features in our app for Slack. Please contact us if you would
                    like your personal information removed.
                </Paragraph>
                <Paragraph>
                    Your continued use of our website and app for Slack will be
                    regarded as acceptance of our practices around privacy and
                    personal information. If you have any questions about how we
                    handle user data and personal information, feel free to
                    contact us.
                </Paragraph>
                <Paragraph>
                    If you have any questions about this Privacy Policy, please
                    contact us by email:{' '}
                    <Link href="mailto:hello@hifiv.es">hello@hifiv.es</Link>
                </Paragraph>

                <Paragraph>
                    This policy is effective as of 26 Aug 2023, and it also
                    applies to any information collected through our app for Slack.
                </Paragraph>
            </Wrapper>
            <Footer />
        </Page>
    )
}

export default Privacy
