import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/core';

function Item({ href, children, isExternal = false }) {
  const linkStyle = {
    color: 'brand.beige',
    pr: '8',
    fontFamily: 'heading',
    fontSize: 'leg',
    fontWeight: 'medium',
  };

  if (isExternal) {
    return (
      <Link href={href} isExternal={isExternal} {...linkStyle}>
        {children}
      </Link>
    );
  }

  if (href.charAt(0) === '#') {
    return (
      <Link href={href} {...linkStyle}>
        {children}
      </Link>
    );
  }

  return (
    <Link as={RouterLink} to={href} {...linkStyle}>
      {children}
    </Link>
  );
}

export default Item;
