import React from 'react';

import { PseudoBox } from '@chakra-ui/core';

function Button({
  children, width, onClick, small = false,
}) {
  return (
    <PseudoBox
      as="button"
      height={small ? '38px' : [46, 46, 46, '56px']}
      lineHeight="none"
      width={width}
      px={small ? 5 : 8}
      fontSize={small ? '14px' : [16, 16, 16, 20]}
      fontFamily="heading"
      borderRadius={28}
      onClick={onClick}
      bg="pink.100"
      _hover={{ bg: 'pink.50' }}
    >
      {children}
    </PseudoBox>
  );
}

export default Button;
