import React from 'react';
import { Box, Text } from '@chakra-ui/core';
import Button from '../../../components/Button';

import { apiUrl } from '../../../environment';
import Wrapper from '../../../components/Wrapper';

function BigText() {
  return (
    <Box>
      <Wrapper px={4} py={['16', '16', '16', '48']}>
        <Text
          fontSize={[40, 40, 40, 90]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          fontFamily="heading"
          as="h2"
          color="brand.beige"
        >
          Give your first high five
        </Text>
        <Box display="flex" alignItems="center" justifyContent="center" mt="4">
          <a href={`${apiUrl}signup/slack`}>
            <Button variantColor="yellow" size="lg">
              Connect to Slack
            </Button>
          </a>
        </Box>
      </Wrapper>
    </Box>
  );
}

export default BigText;
