import { theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: 'halcom, sans-serif',
    script: 'verveine, serif',
  },
  colors: {
    ...theme.colors,
    brand: {
      yellow: '#FFD92A',
      beige: '#FCF5EB',
    },
    pink: {
      50: '#ffe3eb',
      100: '#ffb3c3',
      200: '#fd819b',
      300: '#fc5174',
      400: '#fc264c',
      500: '#e31433',
      600: '#b10c27',
      700: '#7f051c',
      800: '#4d0010',
      900: '#1e0004',
    },
    gray: {
      50: '#edf1fc',
      100: '#ced6e3',
      200: '#afbace',
      300: '#8f9fba',
      400: '#7084a7',
      500: '#576a8d',
      600: '#44536f',
      700: '#303b4f',
      800: '#1c2331',
      900: '#050c15',
    },
  },
};

export default customTheme;
