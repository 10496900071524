const links = [
  {
    href: '/',
    title: 'Home',
  },
  {
    href: '#pricing',
    title: 'Pricing',
  },
  {
    href: 'mailto: hello@hifiv.es',
    title: 'Support',
    isExternal: true,
  },
];

export default links;
